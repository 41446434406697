import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [{
  name: 'menu.dashboard',
  key: 'dashboard',
  children: [{
    name: 'menu.dashboard.workplace',
    key: 'dashboard/workplace'
  }]
}, {
  name: 'menu.order',
  key: 'order',
  children: [{
    name: 'menu.order.list',
    key: 'order/list'
  }]
}, {
  name: 'menu.store',
  key: 'store',
  children: [{
    name: 'menu.store.list',
    key: 'store/list'
  }, {
    name: 'menu.store.promotion.list',
    key: 'store/promotion/list'
  }, {
    name: 'menu.store.apply',
    key: 'store/apply'
  }]
}, {
  name: 'menu.reward',
  key: 'reward',
  children: [{
    name: 'menu.reward.list',
    key: 'reward/list'
  }]
}, {
  name: 'menu.commission',
  key: 'commission',
  children: [{
    name: 'menu.commission.list',
    key: 'commission/list'
  }]
}, {
  name: 'menu.activity',
  key: 'activity',
  children: [{
    name: 'menu.activity.escreenshot.list',
    key: 'activity/escreenshot/list'
  }]
}, {
  name: 'menu.union_platform',
  key: 'union_platform',
  children: [{
    name: 'menu.union_platform.eleme.order_list',
    key: 'union_platform/eleme/order_list'
  }, {
    name: 'menu.union_platform.meituan.order_list',
    key: 'union_platform/meituan/order_list'
  }]
}, {
  name: 'menu.user',
  key: 'user',
  children: [{
    name: 'menu.user.list',
    key: 'user/list'
  }, {
    name: 'menu.user.withdrawal',
    key: 'user/withdrawal'
  }, {
    name: 'menu.user.balance',
    key: 'user/balance'
  }, {
    name: 'menu.user.feedback',
    key: 'user/feedback'
  }]
}, {
  name: 'menu.setting',
  key: 'setting',
  children: [{
    name: 'menu.setting.admin',
    key: 'setting/admin/list'
  } // {
  //   name: 'menu.setting.official_account',
  //   key: 'setting/official_account',
  // },
  // {
  //   name: 'menu.setting.mini_program',
  //   key: 'setting/mini_program',
  // },
  // {
  //   name: 'menu.setting.commission',
  //   key: 'setting/commission',
  // },
  // {
  //   name: 'menu.setting.customer_service',
  //   key: 'setting/customer_service',
  // },
  // {
  //   name: 'menu.setting.map',
  //   key: 'setting/map',
  // },
  // {
  //   name: 'menu.setting.filesystem',
  //   key: 'setting/filesystem',
  // },
  // {
  //   name: 'menu.setting.settle',
  //   key: 'setting/settle',
  // },
  // {
  //   name: 'menu.setting.share',
  //   key: 'setting/share',
  // },
  // {
  //   name: 'menu.setting.sms',
  //   key: 'setting/sms',
  // },
  // {
  //   name: 'menu.setting.withdrawal',
  //   key: 'setting/withdrawal',
  // },
  ]
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;

    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }

    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;

      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }

      if (!visible) {
        continue;
      }

      if (route.children && route.children.length) {
        const newRoute = { ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);

        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route
        });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];

    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }

    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};

export default useRoute;