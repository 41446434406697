import "@arco-design/web-react/es/Modal/style";
import _Modal from "@arco-design/web-react/es/Modal";
import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios';
import { CODE_SUCCESS } from './apiConstants'; // 配置 baseURL

const baseURL = '/admin-api'; // 创建 Axios 实例

const instance = axios.create({
  baseURL,
  timeout: 10000
}); // 请求拦截器

instance.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  const adminToken = localStorage.getItem('adminToken');
  console.log('adminToken', adminToken);

  if (adminToken) {
    config.headers['Authorization'] = `Bearer ${adminToken}`;
  }

  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
}); // 响应拦截器

instance.interceptors.response.use(response => {
  // 对响应数据做些什么
  const {
    code,
    msg
  } = response.data;

  if (code !== CODE_SUCCESS) {
    _Message.error(msg);

    return Promise.reject(response);
  }

  return response;
}, error => {
  console.log('http error status', error);

  if (error.response.status === 401 && window.location.pathname.replace(/\//g, '') !== 'login') {
    localStorage.removeItem('adminToken');

    _Modal.confirm({
      title: '登陆过期,请重新登陆',
      content: '如需保持页面,请打开新窗口登陆后取消对话框',
      onOk: () => {
        window.location.pathname = '/login';
      }
    });
  } // 对响应错误做些什么


  return Promise.reject(error);
}); // 导出 Axios 实例

export default instance;